/** @jsx jsx */
import { jsx, SxStyleProp, useColorMode } from "theme-ui";
import { Button } from "rebass";

export interface ThemeSwitchProps {
  sx?: SxStyleProp;
}

//const modes = ["light", "dark", "pink"];
const modes = ["light", "dark"];

const ThemeSwitcher: React.FC<ThemeSwitchProps> = ({ sx }) => {
  const [colorMode, setColorMode] = useColorMode();

  return (
    <Button
      sx={{
        variant: "links.nav", 
        fontSize: [1, 1, 2],
        bg: "backgroundElevationOpacity",
        color: "inherit",
        "&.active": {
          color: "text",
          bg: "backgroundElevation"
        },
        "&:hover": {
          backgroundColor: "bigTitle",
          color: "background"
        },
        ...sx
      }}
      onClick={() => {
        const index = modes.indexOf(colorMode);
        const next = modes[(index + 1) % modes.length];
        setColorMode(next);
      }}
    >
      Change colors
    </Button>
  );
};

export default ThemeSwitcher;
