import React from "react";
import { Box, Flex, Heading, Text } from "rebass";
import ThemeSwitch from "../components/ThemeSwitch";

const Footer = () => {
  return (
    <>
      <Box pb={[5,6]}>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          variant="container"
          mb={[0, 0, 0]}
        >
          <Box width={[1 / 1]} mb={[3]}>
            <Heading as="h6" variant="catHeading"></Heading>
            <Flex flexWrap="wrap">
              <Box width={[1/1,1/2]}>
                <Heading
                  as="h2"
                  fontFamily="display"
                  fontSize={[6, 7]}
                  mt={[5,0,0,0]}
                  mb={[1, 1, 1, 2]}
                >
                  UX and EyeCandy. 
                </Heading>
                <Text as="p" sx={{ display: "inline"}}>&copy; All rights reserved. Images belong to the brands. </Text>
              </Box>
              <Box width={[1/1, 1/2]} textAlign={["left","right"]} mt={[3,0]}>
                <ThemeSwitch />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Footer;
