import { useState, useEffect } from "react";

const useIsScrolled = (topValue?: number) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(top > (topValue ?? 0));
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topValue]);

  return isScrolled;
};

export default useIsScrolled;
