/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { useEffect } from "react";
import { Heading, Flex, Box } from "rebass";
import Layout from "../layouts/";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Contact() {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.backgroundElevation as string;
  const projectTextColor = theme?.colors?.text as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Flex flexWrap="wrap" alignItems="center" variant="containerPage">
          <Box>
            <Heading as="h1" variant="bigTitle" className="uppercase">
              Say Hi,
              <br />
              Don't be shy
            </Heading>
          </Box>
        </Flex>
        <Footer />
      </main>
    </Layout>
  );
}
